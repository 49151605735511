import React from "react";
import { NavMenu } from "@shopify/app-bridge-react";


const ShopifyNavbar = () => {
    return (
      <>
        <NavMenu>
          <a href="/" rel="home">
            Home
          </a>
          <a href="/pricing">Subscription plans</a>
        </NavMenu>
      </>
    );
  };
  
  export default ShopifyNavbar;