import React from "react";
import "./styles/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Pricing from "./Pricing";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/pricing" element={<Pricing />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
