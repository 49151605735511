import React, {useState, useEffect, Suspense} from "react"
import { useSearchParams } from "react-router-dom"
import ShopifyNavbar from "./components/ShopifyNavbar"
import ShopifyLoader from "./components/ShopifyNavbar"
import useCheckInstallation from "./customHooks/useCheckInstallation"

const Home = () => {
  let [loading, setLoading] = useState(true)
  const [visibleChildren, setVisibleChildren] = useState([])
  const childrenCount = 1

  const searchParams = useSearchParams()
  let params;

  searchParams.forEach((value, key) => {
    params = params ? params + "&&" + key + "=" + value : key + "=" + value;
  })

  const loaderResult = useCheckInstallation(params)

  useEffect(() => {
    if (!localStorage.getItem("shop_details")) {
      localStorage.setItem("shop_details", JSON.stringify(params))
    }
    if (!params && localStorage.getItem("shop_details")) {
      params = JSON.parse(localStorage.getItem("shop_details"))
    }
  }, [params])

  useEffect(() => {
    setLoading(loaderResult.loading)
  }, [loaderResult])

  useEffect(() => {
    if(!loading) {
      const timeouts = [];
      for (let i = 0; i < childrenCount; i++) {
        const delay = (i + 1) * 3000
        timeouts.push(
          setTimeout(() => {
            setVisibleChildren((prev) => [...prev, i])
          }, delay)
        )
      }
      return () => {
        timeouts.forEach(clearTimeout)
      }
    }
  }, [loading])

  if (loading) {
    return <div className="shopifyLoader"></div>
  }

  return (
    <>
      <Suspense fallback={<ShopifyLoader />}>
        {visibleChildren.map((index) => (
          <ShopifyNavbar key={index} index={index + 1} />
        ))}
        <iframe
          src={`https://script.masscat.app/apcscript?serverData=${params}`}
          title="description"
          width="100%"
          height="99%"
          frameBorder="0"
          style={{ overflow: "none", height: "99vh" }}
        ></iframe>
      </Suspense>
    </>
  );
};

export default Home;
