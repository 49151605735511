import { useEffect, useState } from 'react'
import axios from 'axios'

const useCheckInstallation = (params) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const checkIsInstalled = async () => {
      setLoading(true)
      setError(null)
      try {
        const apcscript = axios.create({
          baseURL: "https://script.masscat.app/apcscript",
        })
        
        let isInstalled = await apcscript.get(
          `/is_installed.php?serverData=${params}`
        )
        
        if (isInstalled.data.message === "installed") {
          const myAwesomeScript = document.createElement("script");
          myAwesomeScript.setAttribute(
            "src",
            "https://script.masscat.app/apcscript/app-bridge-react.js?v=12345689"
          )
          document.head.appendChild(myAwesomeScript)
        } else {
          const installUrl = `https://script.masscat.app/apcscript?serverData=${params}`
          window.location.href = installUrl
        }
      } catch (err) {
        setError(err?.message || 'An error occurred')
        console.log(err?.message)
      } finally {
        setLoading(false)
      }
    };

    checkIsInstalled()
  }, [params])

  return { loading, error }
};

export default useCheckInstallation
