// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopifyLoader:before {
  content: "";
  display: block;
  position: absolute;
  left: -6px;
  top: -6px;
  height: 100%;
  width: 100%;
  border-top: 6px solid rgba(0, 0, 0, 0.8);
  border-left: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid transparent;
  border-radius: 100%;
}

.shopifyLoader {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  animation: rotation 1s infinite linear;
  border: 6px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,YAAY;EACZ,WAAW;EACX,wCAAwC;EACxC,kCAAkC;EAClC,oCAAoC;EACpC,mCAAmC;EACnC,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,uBAAuB;EAIvB,sCAAsC;EACtC,oCAAoC;EACpC,mBAAmB;AACrB;AA0BA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".shopifyLoader:before {\n  content: \"\";\n  display: block;\n  position: absolute;\n  left: -6px;\n  top: -6px;\n  height: 100%;\n  width: 100%;\n  border-top: 6px solid rgba(0, 0, 0, 0.8);\n  border-left: 6px solid transparent;\n  border-bottom: 6px solid transparent;\n  border-right: 6px solid transparent;\n  border-radius: 100%;\n}\n\n.shopifyLoader {\n  height: 60px;\n  width: 60px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin: -30px 0 0 -30px;\n  -webkit-animation: rotation 1s infinite linear;\n  -moz-animation: rotation 1s infinite linear;\n  -o-animation: rotation 1s infinite linear;\n  animation: rotation 1s infinite linear;\n  border: 6px solid rgba(0, 0, 0, 0.2);\n  border-radius: 100%;\n}\n\n@-webkit-keyframes rotation {\n  from {\n    -webkit-transform: rotate(0deg);\n  }\n  to {\n    -webkit-transform: rotate(359deg);\n  }\n}\n@-moz-keyframes rotation {\n  from {\n    -moz-transform: rotate(0deg);\n  }\n  to {\n    -moz-transform: rotate(359deg);\n  }\n}\n@-o-keyframes rotation {\n  from {\n    -o-transform: rotate(0deg);\n  }\n  to {\n    -o-transform: rotate(359deg);\n  }\n}\n@keyframes rotation {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(359deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
