import React, { useState, Suspense, useEffect } from "react"
import ShopifyNavbar from "./components/ShopifyNavbar"
import ShopifyLoader from "./components/ShopifyNavbar"
import { useSearchParams } from "react-router-dom"
import useCheckInstallation from "./customHooks/useCheckInstallation"

const Pricing = () => {
  let [loading, setLoading] = useState(true)
  const [visibleChildren, setVisibleChildren] = useState([])
  const childrenCount = 1
  const searchParams = useSearchParams()
  let params;
  let shopDetails;

  searchParams.forEach((value, key) => {
    params = params ? params + "&&" + key + "=" + value : key + "=" + value;
  })

  if(!params) {
    shopDetails = JSON.parse(localStorage.getItem("shop_details"))
  } else {
    shopDetails = params
  }
  
  const loaderResult = useCheckInstallation(shopDetails)

  useEffect(() => {
    setLoading(loaderResult.loading)
  }, [loaderResult])

  useEffect(() => {
    if(!loading) {
      const timeouts = []
      for (let i = 0; i < childrenCount; i++) {
        const delay = (i + 1) * 1000
        timeouts.push(
          setTimeout(() => {
            setVisibleChildren((prev) => [...prev, i])
          }, delay)
        )
      }
      return () => {
        timeouts.forEach(clearTimeout)
      }
    }
  }, [loading])

  if (loading) {
    return <div className="shopifyLoader"></div>
  }

  return (
    <>
      <Suspense fallback={<ShopifyLoader />}>
        {visibleChildren.map((index) => (
            <ShopifyNavbar key={index} index={index + 1} />
        ))}
        <iframe
          src={`https://script.masscat.app/apcscript/pricing.php?serverData=${shopDetails}`}
          title="description"
          width="100%"
          frameBorder="0"
          style={{ overflow: "none", height: "99vh" }}
        ></iframe>
      </Suspense>
    </>
  );
};

export default Pricing;
